import React from 'react';
import Button from '../atoms/Button';

interface WhiteCardProps {
  title: string;
  body: string;
  image: {
    altText: string;
    localFile: any;
  };
  button?: {
    label: string;
    url: string;
  };
}

const WhiteCard = ({ title, body, image, button }: WhiteCardProps) => (
  <div
    key={title}
    className="flex flex-col items-center flex-1 text-center rounded-[10px] px-8 bg-white py-12"
  >
    <div className="flex flex-col items-center flex-grow">
      <div className="mb-8">
        <img
          src={image.localFile.url}
          alt={image.altText}
          className="w-auto h-40 select-none"
        />
      </div>
      <h2 className="mb-4 u-h3">{title}</h2>
      <div
        className="max-w-xs mx-auto u-p"
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </div>
    {button && (
      <div className="pt-8 text-center">
        <Button to={button.url} variant="outline">
          {button.label}
        </Button>
      </div>
    )}
  </div>
);

export default WhiteCard;
