import { useTransform, useViewportScroll } from 'framer-motion';
import React from 'react';
import BeansLeft from '../atoms/BeansLeft';
import BeansRight from '../atoms/BeansRight';
import Button from '../atoms/Button';

interface RightAroundTheCornerSectionProps {
  image: {
    altText: string;
    localFile: any;
  };
  title: string;
  body: string;
  button: {
    label: string;
    url: string;
  };
}

const RightAroundTheCornerSection = ({
  image,
  title,
  body,
  button,
}: RightAroundTheCornerSectionProps) => {
  const { scrollY } = useViewportScroll();
  const y = useTransform(scrollY, [0, 5000], [100, -700]);
  const y2 = useTransform(scrollY, [0, 5000], [300, -300]);

  return (
    <section className="relative pt-24 pb-32 overflow-hidden">
      <BeansLeft className="absolute left-0 -z-1" style={{ y }} />
      <BeansRight className="absolute right-0 -z-1" style={{ y: y2 }} />
      <div className="container text-center">
        <img
          src={image.localFile.url}
          alt={image.altText}
          className="w-auto h-32 mx-auto mb-8"
        />
        <h2 className="mb-4 u-h2">{title}</h2>
        <p className="max-w-2xl mx-auto mb-8">{body}</p>
        {button.url && <Button to={button.url}>{button.label}</Button>}
      </div>
    </section>
  );
};

export default RightAroundTheCornerSection;
