import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import SimpleHeroSection from '../components/molecules/SimpleHeroSection';
import WhiteCard from '../components/molecules/WhiteCard';
import NewsletterSection from '../components/organisms/NewsletterSection';
import { PromoPopup } from '../components/organisms/PromoPopup';
import RightAroundTheCornerSection from '../components/organisms/RightAroundTheCornerSection';

const Contact = ({
  data: {
    page,
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo post={page} />

        <SimpleHeroSection>
          <div className="container">
            <h1 className="mb-12 text-center u-h2">{page.title}</h1>

            <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
              {page.contact?.cards?.map((card) => (
                <WhiteCard key={card.title} {...card} />
              ))}
            </div>
          </div>
        </SimpleHeroSection>

        <RightAroundTheCornerSection
          {...page.contact?.rightAroundTheCornerSection}
        />
        <NewsletterSection />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query ContactPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
      contact {
        cards {
          title
          body
          image {
            localFile {
              url
            }
            altText
          }
          button {
            label
            url
          }
        }
        rightAroundTheCornerSection {
          image {
            altText
            localFile {
              url
            }
          }
          title
          body
          button {
            label
            url
          }
        }
      }
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default Contact;
